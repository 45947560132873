import { ApolloError, gql, useQuery } from '@apollo/client';

const INVITES_FORM_MESSAGES = gql`
  query micrositeInvitesFormMessages(
    $accountSlug: String!
    $promotionSlug: String!
  ) {
    micrositeInvitesFormMessages(
      accountSlug: $accountSlug
      promotionSlug: $promotionSlug
    ) {
      msgNoInvite
      msgInvalidInvite
      token
      captchaSiteKey
      status
      msgInputInvite
      msgReaccess
    }
  }
`;

interface InvitesFormMessages {
  msgNoInvite: string;
  msgInvalidInvite: string;
  token: string;
  captchaSiteKey: string;
  status: string;
  msgInputInvite: string;
  msgReaccess: string;
}

export default function useFetchInvitesFormMessages(
  accountSlug: String,
  promotionSlug: String
): {
  loading: boolean;
  data: InvitesFormMessages | null;
} {
  const { data, loading } = useQuery<{
    micrositeInvitesFormMessages: InvitesFormMessages;
  }>(INVITES_FORM_MESSAGES, {
    variables: {
      accountSlug,
      promotionSlug,
    },
    onError: (error: ApolloError) => {
      console.log({
        gql: 'INVITES_FORM_MESSAGES',
        apolloError: error,
      });
    },
  });

  if (loading) {
    return {
      loading,
      data: null,
    };
  }

  return {
    loading,
    data: data?.micrositeInvitesFormMessages || null,
  };
}
