interface EnterInviteLinkUsedButtonPresets {
  message?: string;
}
export default function EnterInviteLinkUsedButton({
  message,
}: EnterInviteLinkUsedButtonPresets) {
  return (
    <div className="flex justify-content-center">
      <p className="mb-0">
        {message || 'InviteLinkCode has already been used!'}
      </p>
    </div>
  );
}
