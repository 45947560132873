import BlockEnterButton from '@/components/Organisms/BlockEnterButton';
import BlockGames from '@/components/Organisms/BlockGames';
import Popup from '@/components/Popup';
import SafeHTML from '@/components/SafeHTML';
import {
  PromotionStatus,
  PromotionSteps,
  PromotionVisibility,
} from '@/modules/[slug]/interface';
import useAuthContext, {
  MessageResult,
  MessageResultStatus,
} from '@/providers/AuthProvider';
import useMicrositeContext from '@/providers/MicrositeContextProvider';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

export default function Start(): JSX.Element {
  const router = useRouter();

  const { isAuth, setAuth, messageResultStatus, setMessageResultStatus } =
    useAuthContext();

  const {
    setOpenSettingDraft,
    setAux,
    promotionSlug,
    initial,
    promotionStatus,
    accountSlug,
    visibility,
    startPage,
    segments,
    showPopups,
  } = useMicrositeContext();

  const nextPage = useMemo(() => initial.steps as any, []);

  const nextStep = () => {
    if (
      !isAuth() &&
      promotionStatus === PromotionStatus.DRAFT &&
      visibility === PromotionVisibility.PRIVATE
    ) {
      setOpenSettingDraft(true);
    } else {
      router.push(`/${promotionSlug}/${nextPage[1]}`);
    }
  };

  const setStoredValue = (value?: any) => {
    if (value) {
      setAux(value);
    }
    nextStep();
  };

  const setToken = (token: string, messageResult?: MessageResult) => {
    if (messageResult) {
      setMessageResultStatus(messageResult);
    }
    setAuth(token);
    if (messageResult?.status === MessageResultStatus.REDIRECT) {
      router.push(`/${promotionSlug}/result`);
    } else if (messageResult?.status === MessageResultStatus.UNUSED) {
      nextStep();
    }
  };

  const redirectResultPage = () => {
    router.push(`/${promotionSlug}/result`);
  };

  return (
    <>
      {startPage && (
        <>
          {Object.keys(startPage).map((template: string) => (
            <div
              id={`blkd-section-start-block-${template}`}
              className={`blkd-block col-12 blkd-block-${template}`}
              key={`block-${template}`}
            >
              {['top-content', 'bottom-content'].includes(template) && (
                <div className="row justify-content-center content">
                  <SafeHTML
                    html={startPage[template]['content'].defaultValue}
                    className="col col-12"
                  />
                </div>
              )}

              {['enter-button', 'enter-button-20'].includes(template) && (
                <BlockEnterButton
                  messageResultStatus={messageResultStatus}
                  isAuth={isAuth()}
                  promotionSlug={promotionSlug}
                  accountSlug={accountSlug}
                  visibility={initial.visibility}
                  status={initial.status}
                  setToken={setToken}
                  template={template}
                  presets={startPage[template]}
                  goNextStep={nextStep}
                  redirectResultPage={redirectResultPage}
                />
              )}

              <BlockGames
                template={template}
                presets={startPage[template]}
                segments={segments}
                goNextStep={nextStep}
                currentStep={PromotionSteps.START}
                setStoredValue={setStoredValue}
              />
            </div>
          ))}
          <Popup page={PromotionSteps.START} popups={showPopups} />
        </>
      )}
    </>
  );
}
