import { useEffect, useMemo } from 'react';
import EnterButtonSource2 from './source';

interface PresetValues {
  defaultValue: string;
  contentType: string;
}

interface Props {
  presets: Record<string, PresetValues>;
  status: string;
  nextStep?: () => void;
}

export default function EnterButton2({
  presets,
  status,
  nextStep,
}: Props): JSX.Element {
  const presetsUpdated = useMemo(
    () => ({
      showEnterButton: presets['show-enter-button']?.defaultValue,
      enterButtonLabelExpired:
        presets['enter-button-label-expired']?.defaultValue,
      enterButtonLabelLive: presets['enter-button-label-live']?.defaultValue,
      buttonTextColorLive: presets['button-text-color-live']?.defaultValue,
      buttonTextColorExpired:
        presets['button-text-color-expired']?.defaultValue,
      showEnterButtonBelowGame:
        presets['show-enter-button-below-game']?.defaultValue,
      buttonBackgroundColorLive:
        presets['button-background-color-live']?.defaultValue,
      buttonBackgroundColorExpired:
        presets['button-background-color-expired']?.defaultValue,
      buttonBackgroundImageLive:
        presets['button-background-image-live']?.defaultValue,
      buttonBackgroundImageExpired:
        presets['button-background-image-expired']?.defaultValue,
      verticalPaddingLive: presets['vertical-padding-live']?.defaultValue,
      verticalPaddingExpired: presets['vertical-padding-expired']?.defaultValue,
      horizontalPaddingLive: presets['horizontal-padding-live']?.defaultValue,
      horizontalPaddingExpired:
        presets['horizontal-padding-expired']?.defaultValue,
      imageAsButtonLive: presets['image-as-a-button-live']?.defaultValue,
      imageWidthLive: presets['image-width-live']?.defaultValue,
      imageHeightLive: presets['image-height-live']?.defaultValue,
      imageAsButtonExpired: presets['image-as-a-button-expired']?.defaultValue,
      imageWidthExpired: presets['image-width-expired']?.defaultValue,
      imageHeightExpired: presets['image-height-expired']?.defaultValue,
    }),
    [presets]
  );

  useEffect(() => {
    if (Object.values(presetsUpdated).length > 0) {
      const enterButton = new EnterButtonSource2();
      enterButton.setPresets(presetsUpdated);
      enterButton.setNextStep(nextStep);
      enterButton.setParameters({
        status,
      });
      enterButton.init();
    }
  }, [presetsUpdated]);

  return (
    <div className="enter-area">
      <button className="btn enter-btn btn-lg btn-primary" />
    </div>
  );
}
