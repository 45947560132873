import Start from '@/modules/[slug]/start';
import useAuthContext from '@/providers/AuthProvider';
import useMicrositeContext from '@/providers/MicrositeContextProvider';
import updateBodyClass from '@/utils/updateBodyClass';
import { useEffect } from 'react';

export default function Home() {
  const { setStep, storedSteps, removeControlSession, generateControlSteps } =
    useMicrositeContext();

  const { isAuth } = useAuthContext();

  useEffect(() => {
    updateBodyClass('step-start');
  }, []);

  useEffect(() => {
    const steps = storedSteps();
    if (steps) {
      const checkedCount = steps.filter((step) => step.checked).length;
      if (checkedCount === steps.length || (checkedCount === 2 && !isAuth())) {
        removeControlSession();
        generateControlSteps();
      }
      setStep('start');
    }
  }, []);

  return <Start />;
}
