import Button from '@/components/Button';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { RedirectResultSectionProps } from './interface';
import styles from './styles.module.scss';
import { useMicrositeCheckResult } from './useMicrositeCheckResult';

export default function EnterRedirectResultButton({
  accountSlug,
  promotionSlug,
  tokenCaptcha,
  redirectResultPage,
  text,
  msgReaccess,
}: RedirectResultSectionProps): JSX.Element {
  const [recaptchaError, setRecaptchaError] = useState<string>('');
  const [captchaSiteKey, setCaptchaSiteKey] = useState<string>(tokenCaptcha);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const { runCheckResult, loading, data, error } = useMicrositeCheckResult();

  useEffect(() => {
    if (error) {
      recaptchaRef.current?.reset();
      const extensions = error?.graphQLErrors[0]?.extensions as Record<
        string,
        string
      >;
      if (extensions && extensions.code === 'VALIDATION_ERROR') {
        const fields = extensions.data as unknown as Record<string, string>;
        setRecaptchaError(fields.token);
        setCaptchaSiteKey(fields.captchaSiteKey);
      }
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      redirectResultPage();
    }
  }, [data]);

  const onChange = () => {
    setRecaptchaError('');
  };

  const submit = () => {
    const token = recaptchaRef.current?.getValue() || '#';
    setRecaptchaError('');
    runCheckResult?.(accountSlug, promotionSlug, token);
  };

  return (
    <div className="container-fluid">
      <div className={styles['form-authentication']}>
        <div className={styles['form-authentication-content']}>
          {msgReaccess && (
            <p
              className="form-label w-100 ql-editor"
              dangerouslySetInnerHTML={{ __html: msgReaccess }}
            />
          )}

          {captchaSiteKey && (
            <>
              <ReCAPTCHA
                sitekey={captchaSiteKey}
                onChange={onChange}
                ref={recaptchaRef}
              />
              {recaptchaError && (
                <div className="form-text text-danger">{recaptchaError}</div>
              )}
            </>
          )}

          <Button
            type="button"
            color="primary"
            onClick={submit}
            spinner={loading}
            className="btn-block mt-3"
          >
            {text}
          </Button>
        </div>
      </div>
    </div>
  );
}
