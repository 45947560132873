import EnterButton from '@/components/EnterButton';
import EnterButton2 from '@/components/EnterButton2';
import { PresetValue, PromotionStatus } from '../interface';

export interface EnterButtonSectionProps {
  template: string;
  presets: Record<string, PresetValue>;
  goNextStep: () => void;
  status: PromotionStatus;
}

export default function EnterButtonSection({
  template,
  presets,
  goNextStep,
  status,
}: EnterButtonSectionProps): JSX.Element {
  switch (template) {
    case 'enter-button':
      return <EnterButton presets={presets} nextStep={goNextStep} />;
    case 'enter-button-20':
      return (
        <EnterButton2 presets={presets} nextStep={goNextStep} status={status} />
      );
    default:
      return <></>;
  }
}
