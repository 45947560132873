import { ApolloError, gql, useMutation } from '@apollo/client';
import { MicrositeSignOn } from './interface';

const MICROSITE_SIGN_ON = gql`
  mutation micrositeSignOn(
    $accountSlug: String!
    $promotionSlug: String!
    $inviteLinkCode: String
    $token: String
  ) {
    micrositeSignOn(
      accountSlug: $accountSlug
      promotionSlug: $promotionSlug
      inviteLinkCode: $inviteLinkCode
      token: $token
    ) {
      token
      status
      resultStatus {
        status
        message
        captchaSiteKey
        msgReaccess
      }
    }
  }
`;

export default function useSignOnMutation(): {
  loading: boolean;
  data?: MicrositeSignOn;
  error?: any;
  called?: boolean;
  runSignOn?: (
    accountSlug: string,
    promotionSlug: string,
    inviteLinkCode: string,
    token: string
  ) => void;
} {
  const [run, { data, error, loading }] = useMutation<{
    micrositeSignOn: MicrositeSignOn;
  }>(MICROSITE_SIGN_ON, {
    onError: (error: ApolloError) => {
      console.log({
        gql: 'MICROSITE_SIGN_ON',
        apolloError: error,
      });
    },
  });

  const runSignOn = (
    accountSlug: string,
    promotionSlug: string,
    inviteLinkCode: string,
    token: string
  ) => {
    run({
      variables: {
        accountSlug,
        promotionSlug,
        inviteLinkCode,
        token,
      },
    });
  };

  if (loading) {
    return {
      loading: true,
    };
  }

  return {
    loading: false,
    data: data?.micrositeSignOn,
    runSignOn,
    error,
  };
}
