import { useMemo } from 'react';
import classNames from 'classnames';
import { PresetValue } from '@/modules/[slug]/interface';

interface Props {
  nextStep: () => void;
  presets: Record<string, PresetValue>;
}

export default function EnterButton({ nextStep, presets }: Props): JSX.Element {
  const myPresets = useMemo(() => {
    const enterButton = presets['enter-button']?.defaultValue;
    return {
      enterButton,
      disabledButton:
        presets['disabled-button']?.defaultValue || enterButton.length === 0,
    };
  }, [presets]);

  return (
    <div
      className={classNames('enter-area', {
        'd-none': myPresets.disabledButton,
      })}
    >
      <button className="btn enter-btn btn-lg btn-primary" onClick={nextStep}>
        {myPresets.enterButton}
      </button>
    </div>
  );
}
