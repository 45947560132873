import removeHtml from '@/utils/removeHtml';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from '../Button';
import SpinnerBox from '../SpinnerBox';
import { EnterAuthenticationButtonProps } from './interface';
import styles from './styles.module.scss';
import useFetchInvitesFormMessages from './useFetchInvitesFormMessages';
import useSignOnMutation from './useSignOnMutation';

export default function EnterAuthenticationButton({
  accountSlug,
  promotionSlug,
  setToken,
  text,
}: EnterAuthenticationButtonProps) {
  const [inviteLinkCode, setInviteLinkCode] = useState<string>('');
  const [msgNoInvite, setMsgNoInvite] = useState<string>('');
  const [msgInputInvite, setMsgInputInvite] = useState<string>('');
  const [msgInvalidInvite, setMsgInvalidInvite] = useState<string>('');
  const [recaptchaError, setRecaptchaError] = useState<string>('');
  const [captchaSiteKey, setCaptchaSiteKey] = useState<string>('');
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const { loading, data, runSignOn, error } = useSignOnMutation();
  const { data: dataInviteFormMessages, loading: loadingInviteFormMessages } =
    useFetchInvitesFormMessages(accountSlug, promotionSlug);

  useEffect(() => {
    if (dataInviteFormMessages) {
      setMsgNoInvite(dataInviteFormMessages.msgNoInvite);
      setMsgInputInvite(dataInviteFormMessages.msgInputInvite);
      setCaptchaSiteKey(dataInviteFormMessages.captchaSiteKey);
    }
  }, [dataInviteFormMessages]);

  useEffect(() => {
    if (data && data.token) {
      setToken(data.token, data?.resultStatus);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      recaptchaRef.current?.reset();
      const extensions = error?.graphQLErrors[0]?.extensions as Record<
        string,
        string
      >;
      if (extensions && extensions.code === 'VALIDATION_ERROR') {
        const fields = extensions.data as unknown as Record<string, string>;
        const msgInvalidInvite = removeHtml(fields.msgInvalidInvite);
        setMsgInvalidInvite(msgInvalidInvite);
        setRecaptchaError(fields.token);
        setCaptchaSiteKey(fields.captchaSiteKey);
      }
    }
  }, [error]);

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInviteLinkCode(value);
    setMsgInvalidInvite('');
  };

  const onChange = () => {
    setRecaptchaError('');
  };

  const submit = () => {
    const token = recaptchaRef.current?.getValue() || '#';

    setMsgInvalidInvite('');
    setRecaptchaError('');
    runSignOn?.(accountSlug, promotionSlug, inviteLinkCode, token);
  };

  if (loadingInviteFormMessages) {
    return <SpinnerBox />;
  }

  return (
    <div className="container-fluid">
      <div className={styles['form-authentication']}>
        <div className={styles['form-authentication-content']}>
          <p
            className="form-label w-100 ql-editor"
            dangerouslySetInnerHTML={{ __html: msgNoInvite }}
          />
          <input
            type="text"
            className="form-control"
            onChange={handleInput}
            value={inviteLinkCode}
            disabled={loading}
            placeholder={msgInputInvite}
          />
          {msgInvalidInvite && (
            <div
              className="form-text text-danger"
              dangerouslySetInnerHTML={{
                __html: msgInvalidInvite,
              }}
            />
          )}

          {captchaSiteKey && (
            <>
              <ReCAPTCHA
                sitekey={captchaSiteKey}
                onChange={onChange}
                ref={recaptchaRef}
                className="mt-3"
              />
              {recaptchaError && (
                <div className="form-text text-danger">{recaptchaError}</div>
              )}
            </>
          )}

          <Button
            type="button"
            className="btn-primary mt-3 btn-block"
            onClick={submit}
            spinner={loading}
          >
            {text}
          </Button>
        </div>
      </div>
    </div>
  );
}
