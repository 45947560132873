import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { MicrositeCheckResult } from './interface';

const CHECK_RESULT = gql`
  query micrositeCheckResult(
    $accountSlug: String!
    $promotionSlug: String!
    $token: String
  ) {
    micrositeCheckResult(
      accountSlug: $accountSlug
      promotionSlug: $promotionSlug
      token: $token
    ) {
      showResultModal
      captchaSiteKey
      redirect
    }
  }
`;

export function useMicrositeCheckResult(): {
  loading: boolean;
  data?: MicrositeCheckResult;
  error?: any;
  runCheckResult?: (
    accountSlug: string,
    promotionSlug: string,
    token: string
  ) => void;
} {
  const [run, { data, loading, error }] = useLazyQuery<{
    micrositeCheckResult: MicrositeCheckResult;
  }>(CHECK_RESULT, {
    onError: (error: ApolloError) => {
      const validations = error.graphQLErrors[0]?.extensions as Record<
        string,
        string
      >;
      if (
        validations.status !== 'error' &&
        validations.status !== 'validation'
      ) {
        console.log({
          gql: 'CHECK_RESULT',
          apolloError: error,
        });
      }
    },
  });

  const runCheckResult = (
    accountSlug: string,
    promotionSlug: string,
    token: string
  ) => {
    run({
      variables: {
        accountSlug,
        promotionSlug,
        token,
      },
    });
  };

  if (loading) {
    return {
      loading: true,
    };
  }

  return {
    loading: false,
    data: data?.micrositeCheckResult,
    runCheckResult,
    error,
  };
}
