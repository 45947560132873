import classNames from 'classnames';
import styles from './styles.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  spinner?: boolean;
  color?: 'primary' | 'secondary';
  onClick?: () => void;
}

export default function Button({
  children,
  spinner,
  type,
  className,
  disabled,
  color,
  onClick,
}: Props): JSX.Element {
  const colors = {
    primary: 'btn-primary',
    secondary: 'btn-secondary',
  };

  return (
    <button
      type={type}
      className={classNames(
        'btn',
        className,
        color ? colors[color] : undefined
      )}
      disabled={disabled || spinner}
      onClick={() => onClick?.()}
    >
      {spinner && (
        <span
          className={classNames(
            'spinner-border spinner-border-sm',
            styles['spinner-margin-bottom'],
            {
              'me-2': children,
            }
          )}
        />
      )}
      {children}
    </button>
  );
}
