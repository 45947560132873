import EnterAuthenticationButton from '@/components/EnterAuthenticationButton';
import EnterInviteLinkUsedButton from '@/components/EnterInviteLinkUsedButton';
import EnterRedirectResultButton from '@/components/EnterRedirectResultButton';
import {
  PresetValue,
  PromotionStatus,
  PromotionVisibility,
} from '@/modules/[slug]/interface';
import EnterButtonSection from '@/modules/[slug]/start/EnterButtonSection';
import { MessageResult, MessageResultStatus } from '@/providers/AuthProvider';
import { useMemo } from 'react';

interface BlockEnterButtonProps {
  messageResultStatus: MessageResult;
  isAuth: boolean;
  promotionSlug: string;
  accountSlug: string;
  visibility: PromotionVisibility;
  status: PromotionStatus;
  redirectResultPage: () => void;
  setToken: (token: string, messageResult?: MessageResult) => void;
  template: string;
  presets: Record<string, PresetValue>;
  goNextStep: () => void;
}

export default function BlockEnterButton({
  messageResultStatus,
  isAuth,
  promotionSlug,
  accountSlug,
  visibility,
  status,
  redirectResultPage,
  setToken,
  template,
  presets,
  goNextStep,
}: BlockEnterButtonProps) {
  const {
    status: resultStatus,
    message,
    captchaSiteKey,
    msgReaccess,
  } = messageResultStatus;

  const text = useMemo(() => {
    let text;
    if (template === 'enter-button') {
      text = presets?.['enter-button']?.defaultValue;
    } else if (template === 'enter-button-20') {
      text = presets?.['enter-button-label-live']?.defaultValue;
    }
    return text || 'Submit';
  }, [template]);

  if (resultStatus === MessageResultStatus.EXPIRED) {
    return <EnterInviteLinkUsedButton message="InviteLink has expired!" />;
  } else if (resultStatus === MessageResultStatus.USED) {
    return <EnterInviteLinkUsedButton message={message} />;
  } else if (resultStatus === MessageResultStatus.REDIRECT && captchaSiteKey) {
    return (
      <EnterRedirectResultButton
        promotionSlug={promotionSlug}
        accountSlug={accountSlug}
        tokenCaptcha={captchaSiteKey}
        msgReaccess={msgReaccess}
        redirectResultPage={redirectResultPage}
        text={text}
      />
    );
  } else if (
    !isAuth &&
    visibility === PromotionVisibility.PRIVATE &&
    status !== PromotionStatus.DRAFT
  ) {
    return (
      <EnterAuthenticationButton
        promotionSlug={promotionSlug}
        accountSlug={accountSlug}
        setToken={setToken}
        text={text}
      />
    );
  } else {
    return (
      <EnterButtonSection
        template={template}
        presets={presets}
        status={status}
        goNextStep={goNextStep}
      />
    );
  }
}
