export default class EnterButtonSource2 {
  constructor() {
    this.presets = {};
    this.parameters = {};
    this.nextStep;

    // Selectors
    this.btnWrapper;
    this.btn;
  }

  setPresets(presets) {
    this.presets = presets;
  }

  setNextStep(nextStep) {
    this.nextStep = nextStep;
  }

  setParameters(parameters) {
    this.parameters = parameters;
  }

  init() {
    this.btnWrapper = document.getElementsByClassName('enter-area');
    this.btn = document.getElementsByClassName('enter-btn');

    this.cloneButton();
    this.fillButtons();

    let that = this;
    for (let i = 0; i < this.btnWrapper.length; i++) {
      var element =
        this.btnWrapper[i].parentElement.nextElementSibling ??
        this.btnWrapper[i].parentElement.previousElementSibling;
      if (this.btnWrapper[i].style.display == 'flex') {
        return element.addEventListener('click', (e) => {
          window.scrollTo({
            top: that.btnWrapper[i].getBoundingClientRect().y - 75,
            behavior: 'smooth',
          });
          [...that?.btnWrapper].forEach((button) => {
            button.querySelector('button').classList.add('blinking');
          });

          setTimeout(() => {
            [...that?.btnWrapper].forEach((button) => {
              button.querySelector('button').classList.remove('blinking');
            });
          }, 2000);
        });
      }
    }
  }

  cloneButton() {
    var btnWrapper = this.btnWrapper;
    var btn = this.btn;

    if (this.presets.showEnterButtonBelowGame) {
      if (btnWrapper.length < 2) {
        var cloneWrapper = btnWrapper[0].cloneNode();
        var cloneBtn = btn[0].cloneNode();
        var bottomContent =
          btnWrapper[0].parentElement.nextElementSibling.nextElementSibling ??
          btnWrapper[0].parentElement.nextElementSibling;
        cloneBtn.style.backgroundSize = 'contain';
        cloneBtn.style.backgroundPosition = 'center';
        cloneBtn.style.backgrounRepeat = 'no-repeat';
        cloneBtn.style.border = 0;

        cloneWrapper.style.display = 'flex';
        cloneWrapper.style.justifyContent = 'center';
        cloneWrapper.style.padding = '12px 0';

        if (btnWrapper.length < 2) {
          bottomContent.insertBefore(cloneWrapper, bottomContent.firstChild);
          bottomContent.children[0].append(cloneBtn);
        }
      } else {
        btnWrapper[1].style.display = 'flex';
      }
    } else {
      if (btnWrapper.length > 1) {
        btnWrapper[1].style.display = 'none';
      }
    }
  }

  redirect() {
    this.nextStep();
  }

  fillButtons() {
    var btnWrapper = this.btnWrapper;
    var btn = this.btn;
    if (this.parameters.status == 'ended') {
      for (var i = 0; i < btn.length; i++) {
        if (this.presets.imageAsButtonExpired) {
          btn[i].style.width = this.presets.imageWidthExpired;
          btn[i].style.height = this.presets.imageHeightExpired;
          btn[i].innerText = '';
          btn[i].style.color = 'transparent';
          btn[i].style.backgroundColor = 'transparent';
          btn[i].style.backgroundImage =
            'url(' + this.presets.buttonBackgroundImageExpired + ')';
          btn[i].style.paddingTop = 0;
          btn[i].style.paddingBottom = 0;
          btn[i].style.paddingLeft = 0;
          btn[i].style.paddingRight = 0;
        } else {
          btn[i].classList.add('disabled');
          btn[i].style.opacity = 0.85;
          btn[i].innerText = this.presets.enterButtonLabelExpired;
          btn[i].style.color = this.presets.buttonTextColorExpired;
          btn[i].style.backgroundColor =
            this.presets.buttonBackgroundColorExpired;
          btn[i].style.paddingTop = this.presets.verticalPaddingExpired + 'px';
          btn[i].style.paddingBottom =
            this.presets.verticalPaddingExpired + 'px';
          btn[i].style.paddingLeft =
            this.presets.horizontalPaddingExpired + 'px';
          btn[i].style.paddingRight =
            this.presets.horizontalPaddingExpired + 'px';
        }
      }
    } else {
      const redirectClick = this.redirect.bind(this);
      for (var l = 0; l < btn.length; l++) {
        btn[l].removeEventListener('click', redirectClick);
        btn[l].addEventListener('click', redirectClick);

        if (this.presets.imageAsButtonLive) {
          btn[l].innerText = '';
          btn[l].style.color = 'transparent';
          btn[l].style.backgroundColor = 'transparent';
          btn[l].style.backgroundImage =
            'url(' + this.presets.buttonBackgroundImageLive + ')';
          btn[l].style.width = this.presets.imageWidthLive;
          btn[l].style.height = this.presets.imageHeightLive;
          btn[l].style.paddingTop = 0;
          btn[l].style.paddingBottom = 0;
          btn[l].style.paddingLeft = 0;
          btn[l].style.paddingRight = 0;
        } else {
          {
            btn[l].innerText = this.presets.enterButtonLabelLive;
            btn[l].style.color = this.presets.buttonTextColorLive;
            btn[l].style.backgroundColor =
              this.presets.buttonBackgroundColorLive;
            btn[l].style.paddingTop = this.presets.verticalPaddingLive + 'px';
            btn[l].style.paddingBottom =
              this.presets.verticalPaddingLive + 'px';
            btn[l].style.paddingLeft =
              this.presets.horizontalPaddingLive + 'px';
            btn[l].style.paddingRight =
              this.presets.horizontalPaddingLive + 'px';
          }
        }
      }
    }
    if (this.presets.showEnterButton) {
      btnWrapper[0].style.display = 'flex';
    }
  }
}
